
import { Component, Vue } from "vue-property-decorator";
@Component
export default class TheNavbar extends Vue {
  private logo = process.env.VUE_APP_AWS_ASSETS + "/logos/logo-uno.png";
  private url_frontoffice = process.env.VUE_APP_FRONT_OFFICE_URL as string;
  private id_comuna = process.env.VUE_APP_ID_COMUNA as string;
  private right: Boolean = false;

  private get tab(): String {
    return this.$router.currentRoute.name!;
  }

  private get userName(): String {
    return (
      this.$store.state.user.name +
      " " +
      this.$store.state.user.father_last_name
    );
  }

  private checkComuna() {
    return (
      Number(process.env.VUE_APP_ID_COMUNA) == 77 &&
      this.$store.state.settings.empresas
    );
  }

  private closeDrawer(route: string) {
    if (this.$router.currentRoute.name == route) {
      this.$router.go(0);
    }
    this.right = false;
  }

  private get isUserLogged(): String {
    return this.$store.state.logged;
  }

  private logout(): void {
    this.$store.dispatch("logout");
    this.right = false;
    this.$store.state.ticket.step = 0;
    window.open(this.url_frontoffice, "_self");
  }

  private routerGo(name: any) {
    if (name && name != "") {
      if (name == "newrequest") {
        window.open(this.url_frontoffice, "_self");
      } else if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          this.$q.notify({
            message: "Ha ocurrido un error",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        });
      }
    }
  }
}
