
import { Component, Vue } from "vue-property-decorator";
import { copyToClipboard, Notify } from "quasar";
@Component
export default class TheFooter extends Vue {
  private copiarClipboard(texto: any) {
    copyToClipboard(texto)
      .then(() => {
        Notify.create({
          color: "positive",
          message: "Se ha copiado el Número de Teléfono.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      })
      .catch(() => {
        Notify.create({
          color: "negative",
          message: "Ha ocurrido un problema. Por favor, inténtelo de nuevo.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      });
  }

  private llamar(data: any) {
    const numero = data;
    window.open("tel:" + numero);
  }
}
