
import { Component, Vue } from "vue-property-decorator";
import TheNavbar from "@/components/global/TheNavbar.vue";
import TheFooter from "@/components/global/TheFooter.vue";
import store from "@/store";

@Component({
  components: {
    TheNavbar,
    TheFooter
  }
})
export default class App extends Vue {
  created() {
    if (this.$store.state.settings.empresas == null)
      this.$axios
        .get("institucions/1", {
          params: {
            filter: {
              fields: ["empresa", "icono_front"]
            }
          }
        })
        .then(response => {
          let config = response.data;
          this.$store.dispatch("saveConfig", config);
        })
        .catch(error => {});
  }
}
