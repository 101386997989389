import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  //   meta: { requiresAuth: false, visibleAuth: true }
  // },
  {
    path: "/",
    name: "newrequest",
    component: () =>
      import(/* webpackChunkName: "newrequest" */ "../views/NewRequest.vue"),
    meta: { requiresAuth: false, visibleAuth: true }
  },
  {
    path: "/confirmar_reserva/:token",
    name: "confirmar_reserva",
    component: () =>
      import(/* webpackChunkName: "newrequest" */ "../views/Login.vue"),
    meta: { requiresAuth: false, visibleAuth: true }
  },
  {
    path: "/cancelar_reserva/:token",
    name: "anular_reserva",
    component: () =>
      import(/* webpackChunkName: "newrequest" */ "../views/Login.vue"),
    meta: { requiresAuth: false, visibleAuth: true }
  },
  {
    path: "/servicios/:service_id",
    name: "service",
    component: () =>
      import(
        /* webpackChunkName: "detailservice" */ "../views/NewRequestFacade.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: true }
  },
  {
    path: "/politicas",
    name: "politicas",
    component: () =>
      import(/* webpackChunkName: "politicas" */ "../views/PoliticasView.vue"),
    meta: {
      visibleAuth: true,
      requiresAuth: false
    }
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: { requiresAuth: false, visibleAuth: false }
  },
  {
    path: "/registro",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue"),
    meta: { requiresAuth: false, visibleAuth: false }
  },
  {
    path: "/missolicitudes",
    name: "myrequests",
    component: () =>
      import(/* webpackChunkName: "myrequests" */ "../views/MyRequests.vue"),
    meta: { requiresAuth: true, visibleAuth: true }
  },
  {
    path: "/bienvenido",
    name: "welcome",
    component: () =>
      import(/* webpackChunkName: "welcome" */ "../views/WelcomeView.vue"),
    meta: { requiresAuth: true, visibleAuth: true }
  },
  {
    path: "/encuesta/:id_ticket/:token",
    name: "encuesta",
    component: () =>
      import(
        /* webpackChunkName: "encuesta" */ "../views/FormEncuestaServicio.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: true },
    props: true
  },
  {
    path: "/respuesta/:token",
    name: "respuesta",
    component: () =>
      import(/* webpackChunkName: "respuesta" */ "../views/FormResponder.vue"),
    meta: { requiresAuth: false, visibleAuth: true },
    props: true
  },
  {
    path: "/solicitud/:service_id",
    name: "newrequestservice",
    component: () =>
      import(/* webpackChunkName: "newrequest" */ "../views/NewRequest.vue"),
    meta: { requiresAuth: false, visibleAuth: true }
  },
  {
    path: "/servicio/:service_id",
    name: "newrequesturl",
    component: () =>
      import(/* webpackChunkName: "newrequest" */ "../views/NewRequestUrl.vue"),
    meta: { requiresAuth: false, visibleAuth: true }
  },
  {
    path: "/reservas/:service_id",
    name: "newdate",
    component: () =>
      import(/* webpackChunkName: "newdate" */ "../views/NewDate.vue"),
    meta: { requiresAuth: false, visibleAuth: true }
  },
  {
    path: "/versolicitud",
    name: "requestdetails",
    component: () =>
      import(
        /* webpackChunkName: "requestdetails" */ "../views/RequestDetails.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: true },
    props: true
  },
  {
    path: "/consultarsolicitud",
    name: "consultar",
    component: () =>
      import(
        /* webpackChunkName: "requestdetails" */ "../views/RequestDetailFormNew.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: true },
    props: true
  },
  {
    path: "/detallesolicitud/:id_ticket",
    name: "requestdetailsnew",
    component: () =>
      import(
        /* webpackChunkName: "requestdetails" */ "../views/RequestDetailsNew.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: true },
    props: true
  },
  {
    path: "/verticket",
    name: "detalleticket",
    component: () =>
      import(
        /* webpackChunkName: "requestdetails" */ "../views/DetalleTicket.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: true },
    props: true
  },
  {
    path: "/micuenta",
    name: "myaccount",
    component: () =>
      import(/* webpackChunkName: "myaccount" */ "../views/MyAccount.vue"),
    meta: { requiresAuth: true, visibleAuth: true }
  },
  {
    path: "/configuracion",
    name: "configuracion",
    component: () =>
      import(/* webpackChunkName: "myaccount" */ "../views/Configuracion.vue"),
    meta: { requiresAuth: true, visibleAuth: true }
  },
  {
    path: "/configuracion/restablecer",
    name: "cambiarpassword",
    component: () =>
      import(
        /* webpackChunkName: "myaccount" */ "../views/CambiarPassword.vue"
      ),
    meta: { requiresAuth: true, visibleAuth: true }
  },
  {
    path: "/contrasena/recuperar",
    name: "forgottenpassword",
    component: () =>
      import(
        /* webpackChunkName: "forgottenpassword" */ "../views/ForgottenPassword.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: false }
  },
  {
    path: "/verificar/correo",
    name: "sendVerification",
    component: () =>
      import(
        /* webpackChunkName: "sendVerification" */ "../views/SendVerification.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: false }
  },
  {
    path: "/contrasena/crear",
    name: "resetpassword",
    component: () =>
      import(
        /* webpackChunkName: "resetpassword" */ "../views/ResetPassword.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: false }
  },
  {
    path: "/cuenta/une_vitacura/eliminar",
    name: "EliminarCuenta",
    component: () =>
      import(
        /* webpackChunkName: "encuesta" */ "@/views/Web/EliminarCuentaView.vue"
      ),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/auth",
    name: "RecoverPassword",
    component: () =>
      import(
        /* webpackChunkName: "recoverpassword" */ "@/components/Common/RecoverPassword.vue"
      ),
    meta: {
      requiresAuth: false, visibleAuth: true
    }
  },
  {
    path: "/empleos",
    name: "newemployment",
    component: () =>
      import(
        /* webpackChunkName: "newemployment" */ "../views/Empleos/NewEmploymentRequest.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: true },
    beforeEnter: (to: any, from: any, next: any) => {
      if (
        Number(process.env.VUE_APP_ID_COMUNA) != 77 ||
        !JSON.parse(localStorage.getItem("settings")!).empresa
      )
        next({ name: "newrequest" });
      else next();
    }
  },
  {
    path: "/postulacion_omil",
    name: "newpostulationomil",
    component: () =>
      import(
        /* webpackChunkName: "newemployment" */ "../views/Empleos/NewPostulationOmil.vue"
      ),
    meta: { requiresAuth: false, visibleAuth: true },
    beforeEnter: (to: any, from: any, next: any) => {
      if (
        Number(process.env.VUE_APP_ID_COMUNA) != 77 ||
        !JSON.parse(localStorage.getItem("settings")!).empresa
      )
        next({ name: "newrequest" });
      else next();
    }
  },
  {
    path: "/mispostulaciones",
    name: "mypostulations",
    component: () =>
      import(
        /* webpackChunkName: "newemployment" */ "../views/Empleos/MyPostulations.vue"
      ),
    meta: { requiresAuth: true, visibleAuth: true },
    beforeEnter: (to: any, from: any, next: any) => {
      if (
        Number(process.env.VUE_APP_ID_COMUNA) != 77 ||
        !JSON.parse(localStorage.getItem("settings")!).empresa
      )
        next({ name: "newrequest" });
      else next();
    }
  },
  {
    path: "/detallepostulacion/:id_postulacion",
    name: "postulationdetails",
    component: () =>
      import(
        /* webpackChunkName: "newemployment" */ "@/components/empleos/MyPostulationDetail.vue"
      ),
    meta: { requiresAuth: true, visibleAuth: true },
    beforeEnter: (to: any, from: any, next: any) => {
      if (
        Number(process.env.VUE_APP_ID_COMUNA) != 77 ||
        !JSON.parse(localStorage.getItem("settings")!).empresa
      )
        next({ name: "newrequest" });
      else next();
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = Store.state.logged;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const visibleAuth = to.matched.some(record => record.meta.visibleAuth);
  if (requiresAuth && !isAuthenticated) {
    next({ name: "login", query: { redirect: to.name } });
  } else if (isAuthenticated && !visibleAuth) {
    if (from.name) {
      next({ name: from.name });
    } else {
      const url_frontoffice = process.env.VUE_APP_FRONT_OFFICE_URL as string;
      window.open(url_frontoffice, "_self");
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = Store.state.logged;
  if (isAuthenticated) {
    if (Date.now() - Date.parse(Store.state.user.created) > 1199999) {
      Store.dispatch("clearCredentials");
      next({
        name: "login",
        query: {
          redirect: to.name,
          timeout: "true"
        }
      });
    }
  }
  next();
});

export default router;
