"use strict";

import Vue from "vue";
import axios from "axios";
import store from "@/store";
import router from "@/router";
// Full config:  https://github.com/axios/axios#request-config
//axios.defaults.baseURL = process.env.VUE_APP_API_PATH || "";
//axios.defaults.headers.common["Authorization"] = store.state.logged? store.state.user.auth_token: null;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
let config = {
  baseURL: process.env.VUE_APP_API_PATH || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};
const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    if (error.response.status == 401) {
      if (store.state.logged) {
        try {
          Vue.axios
            .get(
              "personas/refreshToken?token=" +
                (store.state.user.refresh_token != ""
                  ? store.state.user.refresh_token
                  : localStorage.getItem("refresh_token")),
              {
                headers: {
                  Authorization: "Bearer " + store.state.user.auth_token
                }
              }
            )
            .then(res => {
              const new_auth_token = res.data;
              store.dispatch("updateAuthToken", new_auth_token);
              Vue.axios.defaults.headers.common.Authorization = `Bearer ${new_auth_token}`;
              error.config.headers.Authorization = `Bearer ${new_auth_token}`;
              return Vue.axios(error.config);
            })
            .catch(e => {
              store.dispatch("clearCredentials");
              router.push({
                name: "login",
                query: {
                  timeout: "true",
                  ...(router.currentRoute.name == "newemployment" && {
                    redirect: "newemployment"
                  })
                }
              });
              return Promise.reject(error);
            });
        } catch (e) {
          store.dispatch("clearCredentials");
          router.push({
            name: "login",
            query: {
              timeout: "true",
              ...(router.currentRoute.name == "newemployment" && {
                redirect: "newemployment"
              })
            }
          });
          return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    } else {
      // Do something with response error
      return Promise.reject(error);
    }
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin);

export default Plugin;
