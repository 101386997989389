import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./quasar";
import i18n from "./i18n";
import "./scss/custom.scss";
import "./scss/fonts.scss";
import "./plugins/qcalendar";

import VueGtag from "vue-gtag";

const nombrePlataforma = process.env.VUE_APP_PLATFORM_NAME;
Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_ID_ANALYTIC as string },
    appName: nombrePlataforma,
    pageTrackerTemplate(to: any) {
      let title = to.name;
      if (title == "newrequest") {
        title = "Inicio - ".concat(nombrePlataforma!);
      }
      if (title == "login") {
        title = "Login - ".concat(nombrePlataforma!);
      }
      if (title == "register") {
        title = "Registro -".concat(nombrePlataforma!);
      }
      if (title == "welcome") {
        title = "Bienvenida Usuario -".concat(nombrePlataforma!);
      }
      if (title == "myrequests") {
        title = "Mis Solicitudes -".concat(nombrePlataforma!);
      }
      if (title == "requestdetailsnew") {
        title = "Detalle de la solicitud -".concat(nombrePlataforma!);
      }

      if (title == "consultar") {
        title = "Consultar Solicitud -".concat(nombrePlataforma!);
      }

      if (title == "configuracion") {
        title = "Perfil del usuario -".concat(nombrePlataforma!);
      }
      if (title == "cambiarpassword") {
        title = "Editar Password ".concat(nombrePlataforma!);
      }
      if (title == "newdate") {
        title = "Reservas -".concat(nombrePlataforma!);
      }

      return {
        page_title: title,
        page_path: to.path
      };
    }
  },
  router
);

Vue.config.productionTip = false;
Vue.prototype.$idComuna = Number(process.env.VUE_APP_ID_COMUNA as string);
Vue.prototype.$nombreComuna = process.env.VUE_APP_NOMBRE_COMUNA as string;
Vue.prototype.$nombrePlataforma = process.env.VUE_APP_PLATFORM_NAME as string;

//Truncate text
var filter = function(text: any, length: any, clamp: any) {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  if (content) {
    return content.length > length ? content.slice(0, length) + clamp : content;
  } else {
    return text;
  }
};
Vue.filter("truncate", filter);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
