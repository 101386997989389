import Vue from "vue";

import "./styles/quasar.styl";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";

import {
  Quasar,
  QLayout,
  QHeader,
  QDrawer,
  QPageContainer,
  QPage,
  QToolbar,
  QToolbarTitle,
  QBtn,
  QIcon,
  QList,
  QItem,
  QItemSection,
  QItemLabel,
  QAvatar,
  QTabs,
  QTab,
  QRouteTab,
  QFooter,
  QCard,
  QCardSection,
  QCardActions,
  QTable,
  QTh,
  QTr,
  QTd,
  QDialog,
  QForm,
  QInput,
  QSelect,
  QBadge,
  QBtnDropdown,
  QSeparator,
  QExpansionItem,
  QScrollArea,
  QSpace,
  QRadio,
  QRating,
  ClosePopup,
  QMarkupTable,
  QLinearProgress,
  QTimeline,
  QTimelineEntry,
  QBreadcrumbs,
  QBreadcrumbsEl,
  QImg,
  QPageSticky,
  QCheckbox,
  QTooltip,
  Ripple,
  Loading,
  QToggle,
  QUploader,
  QFile,
  QUploaderAddTrigger,
  QEditor,
  QDate,
  QPopupProxy,
  QTabPanels,
  QTabPanel,
  QField,
  QSlideTransition,
  Notify,
  QCarousel,
  QCarouselSlide
} from "quasar";

Vue.use(Quasar, {
  config: {
    loading: {
      /* Loading defaults */
    },
    Notify: {}
  },
  components: {
    QLayout,
    QHeader,
    QDrawer,
    QPageContainer,
    QPage,
    QToolbar,
    QToolbarTitle,
    QBtn,
    QIcon,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QAvatar,
    QTabs,
    QTab,
    QRouteTab,
    QFooter,
    QCard,
    QCardSection,
    QCardActions,
    QTable,
    QTh,
    QTr,
    QTd,
    QDialog,
    QForm,
    QInput,
    QSelect,
    QBadge,
    QBtnDropdown,
    QSeparator,
    QExpansionItem,
    QScrollArea,
    QSpace,
    QRadio,
    QRating,
    QMarkupTable,
    QTimeline,
    QTimelineEntry,
    QBreadcrumbs,
    QBreadcrumbsEl,
    QImg,
    QPageSticky,
    QCheckbox,
    QTooltip,
    QLinearProgress,
    QToggle,
    QFile,
    QUploaderAddTrigger,
    QUploader,
    QEditor,
    QPopupProxy,
    QDate,
    QTabPanels,
    QTabPanel,
    QField,
    QSlideTransition,
    QCarousel,
    QCarouselSlide
  },
  directives: { ClosePopup, Ripple },
  plugins: { Loading, Notify }
});
