import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    logged: false,
    user: {
      auth_token: "",
      refresh_token: "",
      email_verified: "",
      user_id: "",
      name: "",
      father_last_name: "",
      mother_last_name: "",
      email: "",
      rut: "",
      document_type: "",
      phone: "",
      created: "",
      country: "",
      id_address: "",
      date_birth: "",
      genre: "",
      adress: {
        street: "",
        number: "",
        district: "",
        regions: "",
        hint: ""
      }
    },
    ticket: {
      guest: false,
      guest_id: "",
      guest_name: "",
      guest_father_name: "",
      guest_mother_name: "",
      guest_document_type: "",
      guest_adress_street: "",
      guest_adress_number: "",
      guest_adress_hint: "",
      guest_adress_district: "",
      guest_email: "",
      guest_phone: "",
      guest_date_birth: "",
      guest_genre: "",
      guest_country: "",
      step: 0,
      id: "",
      correlativo: "",
      date: "",
      message: "",
      qr: "",
      default_address: 0,
      service: {
        id: "",
        name: "",
        reservation_type: ""
      },
      files: null,
      motive: "",
      date_ticket: {
        start_time: "",
        end_date: "",
        day: "",
        id: "",
        service_id: ""
      },
      calle: "",
      numero: "",
      aclaratoria: "",
      motivo_reserva: "",
      estado_ticket: "",
      nombre_estado: ""
    },
    employment: {
      step: 0,
      id: ""
    },
    profesional: {
      nombre: ""
    },
    settings: {
      empresas: null,
      icono_front: null
    }
  },
  mutations: {
    updateAuthToken(state, token) {
      localStorage.setItem("auth_token", token);
      state.user.auth_token = token;
    },
    setLoggedStatus(state, user) {
      var base64Url = user.token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      localStorage.setItem("refresh_token", user.refreshToken);
      state.logged = true;
      state.user.auth_token = user.token;
      state.user.refresh_token = user.refreshToken;
      state.user.email_verified = user.emailVerified;
      state.user.user_id = JSON.parse(jsonPayload).id;
      state.user.name = user.nombre;
      state.user.father_last_name = user.apellido_paterno;
      state.user.mother_last_name = user.apellido_materno;
      state.user.email = user.email;
      state.user.rut = user.rut;
      state.user.document_type = user.tipo_documento;
      state.user.phone = user.telefono;
      state.user.date_birth = user.fecha_nacimiento;
      state.user.genre = user.genero;
      state.user.created = user.created;
      state.user.country = user.id_pais;
      state.user.id_address = user.id_direccion;
      state.user.adress.street = user.direccion.calle;
      state.user.adress.number = user.direccion.numero;
      state.user.adress.district = user.direccion.comuna.nombre;
      state.user.adress.regions = user.direccion.comuna.region.nombre;
      state.user.adress.hint =
        user.direccion.aclaratoria == null ? "" : user.direccion.aclaratoria;
    },
    removeLoggedStatus(state) {
      state.logged = false;
      state.user.auth_token = "";
      state.user.refresh_token = "";
      state.user.email_verified = "";
      state.user.user_id = "";
      state.user.name = "";
      state.user.father_last_name = "";
      state.user.mother_last_name = "";
      state.user.email = "";
      state.user.rut = "";
      state.user.phone = "";
      state.user.date_birth = "";
      state.user.genre = "";
      state.user.created = "";
      state.user.id_address = "";
      state.user.adress.street = "";
      state.user.adress.number = "";
      state.user.adress.district = "";
      state.user.adress.regions = "";
      state.user.adress.hint = "";
    },
    setTicketState(state, ticket) {
      state.ticket.step = ticket.step;
      state.ticket.service.id = ticket.service_id;
      state.ticket.service.name = ticket.service_name;
    },
    setTicketMotive(state, ticket) {
      state.ticket.motive = ticket.motive;
      state.ticket.step = ticket.step;
    },
    setTicketGuest(state, guest) {
      state.ticket.guest = true;
      state.ticket.guest_id = guest.rut;
      state.ticket.guest_document_type = guest.tipo_documento;
      state.ticket.guest_adress_district = guest.direccion.comuna;
      state.ticket.guest_adress_street = guest.direccion.calle;
      state.ticket.guest_adress_number = guest.direccion.numero;
      state.ticket.guest_adress_hint =
        guest.direccion.aclaratoria == null ? "" : guest.direccion.aclaratoria;
      state.ticket.guest_name = guest.nombre;
      state.ticket.guest_father_name = guest.apellido_paterno;
      state.ticket.guest_mother_name = guest.apellido_materno;
      state.ticket.guest_email = guest.email;
      state.ticket.guest_phone = guest.telefono;
      state.ticket.guest_date_birth = guest.fecha_nacimiento;
      state.ticket.guest_genre = guest.genero;
      state.ticket.guest_country = guest.id_pais;
    },
    setTicketFinished(state, ticket) {
      state.ticket.id = ticket.id;
      state.ticket.correlativo = ticket.correlativo;
      state.ticket.date = ticket.date;
      state.ticket.message = ticket.message;
      state.ticket.step = ticket.step;
      state.ticket.files = ticket.adjuntos;
      state.ticket.qr = ticket.qr;
      state.ticket.calle = ticket.calle;
      state.ticket.numero = ticket.numero;
      state.ticket.aclaratoria = ticket.aclaratoria;
      state.ticket.motivo_reserva = ticket.motivo_reserva;
      state.ticket.estado_ticket = ticket.estado_ticket;
      state.ticket.nombre_estado = ticket.nombre_estado;
      state.ticket.default_address = ticket.direccion_default;
    },
    setGuestTicketFinished(state, ticket) {
      // anotar el servicio y el nombre del usuario no logeado.
      state.ticket.service.name = ticket.service_name;
      state.ticket.guest_id = ticket.guest_id;
      state.ticket.guest_name = ticket.guest_name;
      state.ticket.guest_father_name = ticket.guest_lastname;
      state.ticket.guest_mother_name = ticket.guest_mother_lastname;
      state.ticket.guest_document_type = "1";
      state.ticket.guest_date_birth = ticket.guest_date_birth;
      state.ticket.guest_genre = ticket.guest_genre;
      state.user.rut = ticket.guest_id;
      state.user.name = ticket.guest_username;
      state.user.father_last_name = ticket.guest_lastname;
      state.user.mother_last_name = ticket.guest_mother_lastname;
      state.user.document_type = "1";
      state.ticket.id = ticket.id;
      state.ticket.date = ticket.date;
      state.ticket.message = ticket.message;
      state.ticket.step = ticket.step;
      state.ticket.files = ticket.adjuntos;
      state.ticket.qr = ticket.qr;
      state.ticket.estado_ticket = ticket.estado_ticket;
    },
    setSectedService(state, service) {
      state.ticket.service.id = service.id;
      state.ticket.service.name = service.nombre;
      state.ticket.service.reservation_type = service.tipo_reserva;
    },
    setTicketStepCero(state) {
      state.ticket.step = 0;
    },

    setTicketStepOne(state) {
      state.ticket.step = 1;
    },
    setTicketStepTwo(state) {
      state.ticket.step = 2;
    },
    setTicketStepThree(state) {
      state.ticket.step = 3;
    },
    setTicketStepTwoDate(state, ticket) {
      state.ticket.date_ticket = {
        start_time: ticket.start_date,
        end_date: ticket.end_date,
        day: ticket.date,
        id: ticket.id_date,
        service_id: ticket.service
      };
      state.ticket.step = 2;
    },
    updateUserData(state, user) {
      state.user.name = user.nombre;
      state.user.father_last_name = user.apellido_paterno;
      state.user.mother_last_name = user.apellido_materno;
      state.user.email = user.email;
      state.user.phone = user.telefono;
      state.user.date_birth = user.fecha_nacimiento;
      state.user.genre = user.genero;
      state.user.country = user.id_pais;
    },
    updateAddressData(state, address) {
      state.user.adress.street = address.calle;
      state.user.adress.number = address.numero;
      state.user.adress.district = address.comuna.nombre;
      state.user.adress.regions = address.comuna.region.nombre;
      state.user.adress.hint =
        address.aclaratoria == null ? "" : address.aclaratoria;
    },
    cleanTicketData(state) {
      state.ticket.guest = false;
      state.ticket.guest_id = "";
      state.ticket.guest_name = "";
      state.ticket.guest_father_name = "";
      state.ticket.guest_mother_name = "";
      state.ticket.guest_document_type = "";
      state.ticket.guest_adress_street = "";
      state.ticket.guest_adress_number = "";
      state.ticket.guest_adress_hint = "";
      state.ticket.guest_adress_district = "";
      state.ticket.guest_date_birth = "";
      state.ticket.guest_genre = "";
      state.ticket.id = "";
      state.ticket.date = "";
      state.ticket.message = "";
      state.ticket.service.id = "";
      state.ticket.service.name = "";
      state.ticket.motive = "";
      state.ticket.files = null;
      state.ticket.calle = "";
      state.ticket.numero = "";
      state.ticket.aclaratoria = "";
      state.ticket.motivo_reserva = "";
    },
    setReservationProfesional(state, profesionalData) {
      state.profesional.nombre = profesionalData;
    },
    setEmailVerified(state, emailVerified) {
      state.user.email_verified = emailVerified;
    },
    saveSettings(state, settings) {
      (state.settings.empresas as any) = !!settings.empresa;
      (state.settings.icono_front as any) = !!settings.icono_front;
    }
  },
  actions: {
    login({ commit }, user) {
      commit("setLoggedStatus", user);
      localStorage.setItem("user", JSON.stringify(user));
    },
    updateUser({ dispatch, commit }, user) {
      commit("updateUserData", user);
      dispatch("updateUserLocalStorage", user);
    },
    updateAddress({ dispatch, commit }, user) {
      commit("updateAddressData", user);
      dispatch("updateAddressLocalStorage", user);
    },
    logout({ dispatch }) {
      dispatch("clearCredentials");
      if (router.currentRoute.name !== "newrequest") {
        const url_frontoffice = process.env.VUE_APP_FRONT_OFFICE_URL as string;
        window.open(url_frontoffice, "_self");
      }
    },
    updateUserLocalStorage({ commit }, user_update) {
      let user = JSON.parse(localStorage.getItem("user")!);
      user.nombre = user_update.nombre;
      user.apellido_paterno = user_update.apellido_paterno;
      user.apellido_materno = user_update.apellido_materno;
      user.email = user_update.email;
      user.telefono = user_update.telefono;
      user.id_pais = user_update.id_pais;
      user.fecha_nacimiento =
        user_update.fecha_nacimiento != null &&
        user_update.fecha_nacimiento != ""
          ? user_update.fecha_nacimiento
          : null;
      user.genero = user_update.genero;
      localStorage.setItem("user", JSON.stringify(user));
    },
    updateAddressLocalStorage({ commit }, address) {
      let user = JSON.parse(localStorage.getItem("user")!);
      user.direccion.calle = address.calle;
      user.direccion.numero = address.numero;
      user.direccion.comuna = address.comuna;
      user.direccion.aclaratoria =
        address.aclaratoria == null ? "" : address.aclaratoria;
      localStorage.setItem("user", JSON.stringify(user));
    },
    submitProfesional({ commit }, profesional) {
      commit("setReservationProfesional", profesional);
    },
    initialize({ commit }) {
      if (localStorage.getItem("user")) {
        let user = JSON.parse(localStorage.getItem("user")!);
        if (Date.now() - Date.parse(user.created) > 1199999) {
          localStorage.removeItem("user");
        } else {
          commit("setLoggedStatus", user);
        }
      }
    },
    clearCredentials({ commit }) {
      commit("removeLoggedStatus");
      localStorage.removeItem("user");
    },
    checkCredentials({ dispatch, state }) {
      if (localStorage.getItem("user")) {
        let user = state.user;
        if (Date.now() - Date.parse(user.created) > 1199999) {
          dispatch("clearCredentials");
          return false;
        }
        return true;
      }
    },
    submitRequestStepOne({ commit }, ticket) {
      commit("setTicketState", ticket);
    },
    submitRequestStepTwo({ commit }, payload) {
      commit("setTicketMotive", payload.ticket);
      if (payload.guest) {
        commit("setTicketGuest", payload.guest);
      }
    },
    submitRequestFinished({ commit }, payload) {
      commit("setTicketFinished", payload);
    },
    submitFinishedGuestRequest({ commit }, payload) {
      commit("setGuestTicketFinished", payload);
    },
    setStateToCero({ commit }) {
      commit("setTicketStepCero");
    },
    setStateToOne({ commit }) {
      commit("setTicketStepOne");
    },
    setStateToTwo({ commit }) {
      commit("setTicketStepTwo");
    },
    setStateToTwoDate({ commit }, payload) {
      commit("setTicketStepTwoDate", payload);
    },
    setStateToThree({ commit }) {
      commit("setTicketStepThree");
    },
    cleanTicket({ commit }) {
      commit("cleanTicketData");
    },
    storeService({ commit }, payload) {
      commit("setSectedService", payload);
    },
    setEmailVerified({ commit }, payload) {
      commit("setEmailVerified", payload);
    },
    updateAuthToken({ commit }, payload) {
      commit("updateAuthToken", payload);
    },
    saveConfig({ commit }, settings) {
      commit("saveSettings", settings);
      localStorage.setItem("settings", JSON.stringify(settings));
    },
    loadConfig({ commit }) {
      let settings = JSON.parse(localStorage.getItem("settings")!);
      commit("saveSettings", settings);
    }
  },
  modules: {}
});
export default store;

store.dispatch("initialize");
